@import 'shared/variables';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'shared/components/button';

:root {
  --color-white: #fff;
  --color-black: #000;

  --color-orange-500: #fc7d23;
  --color-yellow-800: #fed32f;

  --color-green-100: #edf7f0;
  --color-green-400: #48ac98;
  --color-green-700: #006400;
  --color-green-800: rgba(72, 172, 152, 0.2);

  --color-grey-100: #f5f5f5;
  --color-grey-200: #f8f9fc;
  --color-grey-300: #dedede;
  --color-grey-400: #d3d7da;
  --color-grey-500: #e4e9ed;
  --color-grey-600: #ebeff3;
  --color-grey-700: #ccc;
  --color-grey-900: #333;

  --color-red-100: #ffe6e6;
  --color-red-400: #d34343;
  --color-red-800: #f00;
  --color-red-900: #b50000;

  --color-orange-100: #ffe0cb;

  --color-blue-100: #eef7fa;
  --color-blue-200: #6ec0d3;
  --color-blue-300: #50a1ff;
  --color-blue-400: #184da5;
  --color-blue-600: #123d86;
  --color-blue-800: #103d88;

  --font-roboto: 'Roboto', sans-serif;
  --font-roboto-slab: 'Roboto Slab', sans-serif;

  --max-section-width: 960px;
  --max-section-width-lg: 1170px;
}

.modal-header {
  justify-content: space-between;
  --bs-modal-header-padding: 0.75rem 0.75rem !important;
}
.modal-body,
.modal-footer {
  --bs-modal-padding: 0.75rem;
}

input,
select,
textarea,
button {
  outline: none;

  &:active,
  &:focus {
    outline: none;
  }
}

body {
  font: 16px/1.5em var(--font-roboto);
  color: var(--color-grey-900);
  background: var(--color-grey-200);
  min-width: 320px;
  min-height: 100vh;

  h1 {
    font: bold 24px/1.25em var(--font-roboto-slab);

    &.page-title {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: -15px;
        top: -10px;
        width: 50px;
        height: 4px;
        background: var(--color-blue-200);
      }
    }
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 5px 0;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 5px 0;
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 5px 0;
  }

  figure {
    margin: 0;
    padding: 0;
  }

  a {
    color: var(--color-blue-200);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  label {
    font-weight: bold;
    margin-bottom: 6px;
    cursor: pointer;
  }

  .section {
    max-width: var(--max-section-width);
    margin: 0 auto;
  }

  .content {
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    padding: 16px;
    max-width: var(--max-section-width);
    margin: 20px auto 20px;
    overflow: hidden;

    &_no-padding {
      padding: 0;
    }

    &_wide {
      max-width: var(--max-section-width-lg);
    }

    &__outer {
      padding: 25px 0 10px 0;
    }
  }

  hr {
    background: var(--color-grey-300);
    display: block;
    width: 100%;
    height: 1px;
    margin: 1em 0;
    border: none;
  }

  select {
    border: 1px solid var(--color-grey-300);
    border-radius: 3px;
    box-shadow: inset 0 2px 1px 0 rgba(236, 236, 236, 1);
    box-sizing: border-box;
    padding: 0 10px;
  }

  .form-group {
    margin-bottom: 1.5rem;

    input[type='text'],
    input[type='tel'],
    input[type='email'],
    input[type='password'] {
      &.form-control {
        border: 1px solid #dedede;
        box-shadow: inset 0 2px 1px 0 rgba(236, 236, 236, 1);
        box-sizing: border-box;
        height: 40px;
        background: var(--color-white);

        &.error {
          color: var(--color-red-800);
          border: 1px solid var(--color-red-800);
        }

        &.icon {
          padding-right: 40px;
        }
      }
    }

    select {
      &.error {
        color: var(--color-red-800);
        border: 1px solid var(--color-red-800);
      }
    }

    small {
      color: var(--color-red-800);
      display: block;
      margin-top: 5px;
    }
  }

  .tooltip {
    position: absolute;
  }

  .tooltip-arrow {
    position: absolute;
  }

  img.tooltip-icon {
    position: relative;
    top: -2px;
    width: 22px;
    height: 22px;
  }

  fieldset {
    width: 100%;
    padding: 25px 0 10px 0;

    h2 {
      font-size: 18px;
      line-height: 1em;
    }

    a {
      color: var(--color-blue-400);
    }

    .top-line {
      border-top: 1px solid var(--color-grey-300);
    }

    .form-group {
      label {
        font-size: 15px;

        &.big {
          font-size: 18px;
          font-weight: 500;
        }
      }

      select,
      input:not(.form-check-input) {
        font-size: 15px;
        line-height: 1em;
        height: 40px;

        &.big {
          font-size: 32px;
          height: 50px;
        }
      }

      select {
        &.success {
          text-align-last: center;
          color: var(--color-green-400);
          border-width: 2px;
          border-color: var(--color-green-400);
        }
      }

      [readonly] {
        opacity: 0.7;
        background: var(--color-grey-100);
      }

      .form-check {
        .form-check-input {
          height: 16px;
        }
      }
    }
  }

  .reject {
    color: var(--color-red-400);
  }

  .error {
    color: var(--color-red-800);
  }

  .success {
    color: var(--color-green-400);
  }

  .error,
  .success {
    small {
      display: block;
    }
  }

  .text-bolder {
    font-weight: 600;
  }

  .text-bigger {
    font-size: 1.1em;
  }

  .text-smaller {
    font-size: 0.9em;
  }
}

@media only screen and (min-width: 1024px) {
  body {
    h1 {
      font-size: 35px;

      &.page-title {
        &::before {
          top: 20px;
          left: -150px;
          width: 70px;
        }
      }
    }

    h2 {
      font-size: 22px;
    }

    h3 {
      font-size: 22px;
    }

    .content {
      padding: 20px;

      &_no-padding {
        padding: 0;
      }

      &__outer {
        padding: 50px 135px;

        &_wider {
          padding-left: 50px;
          padding-right: 50px;
        }
      }
    }

    .form-group {
      input[type='text'],
      input[type='tel'],
      input[type='email'],
      input[type='password'] {
        &.form-control {
          height: 50px;
        }
      }
    }

    fieldset {
      width: 100%;
      padding: 50px 105px;

      h2 {
        font-size: 30px;
      }

      .form-group {
        label {
          font-size: 16px;

          &.big {
            font-size: 30px;
            margin-bottom: 18px;
          }
        }

        select,
        input:not(.form-check-input) {
          font-size: 16px;
          height: 50px;

          &.big {
            font-size: 40px;
            height: 70px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .content {
    &__outer {
      &_wider {
        padding-left: 135px;
        padding-right: 135px;
      }
    }
  }
}

/* Flow root */

.flow-root {
  min-height: 100vh;
  background: var(--color-grey-600);

  &__wrapper {
    max-width: 1170px;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    > main {
      flex-grow: 1;
    }
  }

  a {
    color: var(--color-grey-900);
    text-decoration: underline;
  }

  label {
    font-weight: normal;
  }

  .content {
    max-width: 1170px;
    box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    margin: 0;

    &_slight {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    }

    @include media-breakpoint-up('lg') {
      border-radius: 10px;
    }
  }

  .line-separator {
    width: 1px;
    height: 100%;
    background: var(--color-grey-300);
    position: relative;
    margin: 0 auto;

    &::before,
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 20px solid var(--color-white);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 2;
    }

    &::after {
      z-index: 1;
      left: 1px;
      border-left: 20px solid var(--color-grey-300);
    }
  }

  .terms {
    table {
      width: 100%;

      td {
        font-size: 13px;
        width: 49%;
        border: 1px solid black;
        padding: 4px;
        text-align: left;
        vertical-align: top;
      }
    }

    p {
      font-size: 13px;
      line-height: 1.2em;
      text-align: left;
      margin: 0 0 0.5em 0;
    }

    h4 {
      margin: 8px 0;
    }
  }

  .top-line {
    border-top: 1px solid var(--color-grey-300);
  }
}

@media only screen and (max-width: 1023px) {
  .flow-root {
    .line-separator {
      width: 100%;
      height: 1px;
      background: var(--color-grey-300);
      position: relative;
      margin-top: 5px;
      margin-bottom: 30px;

      &::before,
      &::after {
        content: '';
        width: 0;
        height: 0;
        border-right: 20px solid transparent;
        border-left: 20px solid transparent;
        border-top: 20px solid var(--color-white);
        position: absolute;
        top: 39px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 2;
      }

      &::after {
        z-index: 1;
        top: 41px;
        border-top: 20px solid var(--color-grey-300);
      }
    }
  }
}

/* Dashboard root */

body {
  #wrapper {
    min-width: 320px;
    max-width: 960px;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    flex-flow: column;
    background: #f4f4f4;

    main {
      flex-grow: 1;
    }

    #content {
      padding: 15px;

      .content-box {
        padding: 20px;
        background: #fff;
        box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.1);

        &.content-box_no-padding {
          padding: 0;
        }
      }
    }

    .notification {
      text-align: center;
      padding: 15px;
    }

    .success {
      color: #9bc350;
    }

    .error {
      color: red;
    }

    label {
      margin: 0;

      a {
        text-decoration: underline;
      }
    }

    a {
      color: #000;
      text-decoration: none;
      cursor: pointer;

      &:not(.btn) {
        &:visited,
        &:active,
        &:focus {
          color: #000;
        }
      }
    }

    h2,
    h3,
    h4 {
      font-family: 'Open Sans', sans-serif;
    }

    h2 {
      font-size: 24px;
      font-weight: normal;
      line-height: 1.2em;
      color: #6fc0d4;
      padding: 0;
      margin: 0;
    }

    h3 {
      font-size: 20px;
      font-weight: bold;
      color: #000;
      margin: 0.6em 0 0 0;
      padding: 0.8em 0 0.8em 13px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background: url(assets/images/caption-bg.png);
        height: 18px;
        width: 3px;
      }
    }

    h4 {
      font-size: 1.2em;
      margin: 0.75em 0;
    }

    p {
      margin: 0.75em 0;
    }

    form {
      .form-group {
        position: relative;
        margin-bottom: 16px;

        label {
          margin-bottom: 5px;

          &.checkbox {
            padding-left: 30px;
            cursor: pointer;
            display: block;
            margin: 2em 0 5px 0;
            position: relative;

            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 16px;
              height: 16px;
              margin-top: -8px;
              left: 0;
              top: 50%;
              background: #fff;
              border: 2px solid #e5e5e5;
              border-radius: 2px;
            }
          }
        }

        input[type='text'],
        input[type='email'],
        input[type='password'],
        select {
          font-size: 15px;
          display: block;
          width: 100%;
          height: 46px;
          border: 1px solid #e5e5e5;
          padding: 0 40px 0 20px;
          box-sizing: border-box;
          box-shadow: 0 1px 1px 0 #e6eff3;

          &:focus {
            box-shadow:
              0 2px 1px 0 #f5f5f5,
              inset 0 0 10px 0 #d8d8d8;
            border: 1px solid #6fc0d4;
          }
        }

        input[type='checkbox'] {
          position: absolute;
          left: -10000px;
        }

        input[type='checkbox']:checked + label::before {
          content: '\2714';
          font-size: 13px;
          line-height: 1em;
          color: #494949;
          background: #e5e5e5;
          display: block;
          height: 16px;
          width: 16px;
        }

        button {
          font-size: 18px;
          text-align: center;
          color: #fff;
          text-shadow: 0 -1px 1px #999;
          background: #6fc0d4;
          height: 58px;
          border: 1px solid #6fc0d4;
          padding: 0 65px;
          border-radius: 3px;
          box-shadow:
            0 2px 1px 0 #f5f5f5,
            inset 0 2px 2px 0 #6fc0d4;
          box-sizing: border-box;
          cursor: pointer;
        }

        small {
          display: block;
          color: red;
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  body {
    #wrapper {
      .hidden-not-mobile {
        display: none;
      }

      h2 {
        font-size: 30px;
      }
    }
  }
}
