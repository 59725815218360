// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$orange: #fd7e14 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$blue: #258bcf;
$yellow: #fbb429;
$green: #9bc350;
$red: #f55d5d;
$yellow1: #fbb429;
$green1: #3db28c;
$red1: #e76663;
$darkBlue: #344b66;
$darkerBlue: #0d4873;
$lightBlue: #12629d;
$lightGray: #e5e8eb;
$lightGray1: #e4e4e4;
$lightGray2: #d8d8d8;
$darkerGray: #979797;
$darkerGray0: #74747b;
$darkerGray1: #595966;
$darkerGray2: #44444f;
$darkGray: #4f4f4f;
$dark: #2e2f36;

$colors: (
  'blue': $blue,
  'indigo': $indigo,
  'purple': $purple,
  'pink': $pink,
  'red': $red,
  'orange': $orange,
  'yellow': $yellow,
  'green': $green,
  'red1': $red1,
  'yellow1': $yellow1,
  'green1': $green1,
  'teal': $teal,
  'cyan': $cyan,
  'white': $white,
  'gray': $gray-600,
  'gray-dark': $gray-800,
  'darkBlue': $darkBlue,
  'darkerBlue': $darkerBlue,
  'lightBlue': $lightBlue,
  'lightGray': $lightGray,
  'lightGray1': $lightGray1,
  'lightGray2': $lightGray2,
  'darkerGray': $darkerGray,
  'darkerGray0': $darkerGray0,
  'darkerGray1': $darkerGray1,
  'darkerGray2': $darkerGray2,
  'darkGray': $darkGray,
  'dark': $dark,
);

$primary: $blue;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $darkBlue;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);

$spacer: 0.25rem;
$spacers: (
  0: 0,
  1: $spacer,
  2: (
    $spacer * 2,
  ),
  3: (
    $spacer * 3,
  ),
  4: (
    $spacer * 4,
  ),
  5: (
    $spacer * 5,
  ),
  6: (
    $spacer * 6,
  ),
  7: (
    $spacer * 7,
  ),
  8: (
    $spacer * 8,
  ),
  9: (
    $spacer * 9,
  ),
  10: (
    $spacer * 10,
  ),
  11: (
    $spacer * 11,
  ),
  12: (
    $spacer * 12,
  ),
  13: (
    $spacer * 13,
  ),
  14: (
    $spacer * 14,
  ),
  15: (
    $spacer * 15,
  ),
  16: (
    $spacer * 16,
  ),
  17: (
    $spacer * 17,
  ),
  18: (
    $spacer * 18,
  ),
  19: (
    $spacer * 19,
  ),
  20: (
    $spacer * 20,
  ),
  21: (
    $spacer * 21,
  ),
  22: (
    $spacer * 22,
  ),
  23: (
    $spacer * 23,
  ),
  24: (
    $spacer * 24,
  ),
  25: (
    $spacer * 25,
  ),
  26: (
    $spacer * 26,
  ),
  27: (
    $spacer * 27,
  ),
  28: (
    $spacer * 28,
  ),
  29: (
    $spacer * 29,
  ),
  30: (
    $spacer * 30,
  ),
);

$sizes: (
  15: 15%,
  25: 25%,
  35: 35%,
  50: 50%,
  65: 65%,
  75: 75%,
  85: 85%,
  90: 90%,
  100: 100%,
  auto: auto,
);

$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1400px,
  xxl: 1600px,
);

$bs-btn-color: #fff !default;
