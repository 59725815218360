.btn {
  &.btn-danger,
  &.btn-info,
  &.btn-danger:hover,
  &.btn-info:hover {
    color: var(--color-white);
  }
}

.button,
button {
  &[disabled] {
    opacity: 0.7;
  }
}

.button {
  font-size: 1em;
  font-weight: 600;
  border: none;
  background: none;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  transition: all 0.5s ease-out;

  &:hover {
    opacity: 0.9;
  }

  &_shadow {
    border: none;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  }

  &_empty {
    text-align: left;
    border: none;
    background: none;
    box-shadow: none;
  }

  &_small {
    font-size: 0.8em;
    padding: 0 12px;
    height: 30px;
  }

  &_middle {
    padding: 0 12px;
    height: 40px;
  }

  &_normal {
    height: 50px;
    padding: 0 15px;

    @include media-breakpoint-up('lg') {
      padding: 0 35px;
    }
  }

  &_font-big {
    font-size: 1.1em;

    @include media-breakpoint-up('lg') {
      font-size: 1.25em;
    }
  }

  &_block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    @include media-breakpoint-up('lg') {
      width: auto;
    }
  }

  &_full {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  &_w120 {
    width: 120px;
  }

  &_white {
    color: var(--color-grey-900);
    background: var(--color-white);
    border: 1px solid var(--color-grey-300);
  }

  &_green {
    color: var(--color-white);
    background: var(--color-green-400);
  }

  &_dark-green {
    color: var(--color-white);
    background: var(--color-green-700);
  }

  &_blue {
    color: var(--color-white);
    background: var(--color-blue-600);
  }

  &_lightBlue {
    color: var(--color-white);
    background: var(--color-blue-400);
  }

  &_lighterBlue {
    color: var(--color-white);
    background: var(--color-blue-200);
  }
}

.btn-success {
  color: var(--color-white) !important;

  &:hover {
    color: var(--color-white);
  }
}

.btn-outline-success {
  &:hover {
    color: var(--color-white);
  }
}

.btn-primary {
  color: var(--color-white) !important;

  &:hover {
    color: var(--color-white);
  }
}
